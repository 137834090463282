import { createRouter, createWebHistory } from '@ionic/vue-router';

import TabsPage from '@/views/TabsPage.vue'

const routes = [
  {
    name:'home',
    path: '/home',
    component: () => import('@/views/TabsPage.vue')
  },
  {
    path: '/',
    component: () => import('@/views/TabsPage.vue')
  },
  {
    path: '/index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/agent'
      },
    
      {
        path: 'Admin',
        component: () => import('@/Admin/views/Tabs.vue')
      },

      // {
      //  
      // }
    ]
  },

  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/agent'
      },
    
      {
        path: 'agent',
        component: () => import('@/Admin/views/Tabs.vue')
      },
      
    ]
  },


  //Agent

  {
    path: '/agent',
    component: () => import('@/Agent/views/Tabs.vue'),
    redirect: '/agent/tabs'
  },


  {
    path: '/agent/newhm',
    component: () => import('@/Agent/views/NewHM.vue'),
   
  },
  
  



  {
    path: '/agent/tabs',
    component: () => import('@/Agent/views/Tabs.vue'),
    children:[

      {
        path: '',
        redirect: '/agent/tabs/home'
      },

      {
        path: 'home',
        component: () => import('@/Agent/views/Home.vue'),
      },
      // {
      //   path: 'categories',
      //  // component: () => import('@/Agent/views/Categories.vue')
      // },
      // {
      //   path: 'notifications',
      //   component: () => import('@/Agent/views/Notifications.vue')
      // },
     
      {
        path: 'myprofile',
        component: () => import('@/Agent/views/MyProfile.vue'),
        // children: [
        
        //   {
        //     name: 'myprofile.posts',
        //     path:'/Agent/tabs/myprofile/posts',
        //     component:()=>import('@/Agent/components/ExploreContainer.vue'),
        //     props:{'page':'myposts'}

        //   }



        // ],
      },
   
  

    ]
  },





  {
    path: '/agent/guidelines',
    component: () => import('@/Agent/views/Guidelines.vue'),
    
  },

  {
    path: '/agent/contact',
    component: () => import('@/Agent/views/Contact.vue'),
    
  },

 
//Admin

{
  path: '/Admin/index',
   component: () => import('@/Admin/views/Index.vue'),

},
  {
    path: '/admin',
 //   component: () => import('@/Admin/views/Tabs.vue'),
   redirect: '/admin/tabs',
  },
   
      {
        path: '/admin/tabs',
        component: () => import('@/Admin/views/Tabs.vue'),
        children:[

          {
            path: '',
            redirect: '/admin/tabs/graph'
          },

          {
            path: 'home',
            
            component: () => import('@/Admin/views/Graph.vue'),
          },
          {
            path: 'sp',
            
            component: () => import('@/Admin/views/sp_home.vue'),
          },
          {
            path: 'schools',
            component: () => import('@/Admin/views/Schools.vue')
          },
          {
            path: 'analytics',
            component: () => import('@/Admin/views/Analytics.vue')
          },
          {
            path: 'graph',
            component: () => import('@/Admin/views/Graph.vue')
          },
         
          // {
          //   path: 'chat',
          //   component: () => import('@/Admin/views/Chat.vue')
          // },
          // {
          //   path: 'profile',
          //   component: () => import('@/Admin/views/MyProfile.vue')
          // },
      

        ]
      },
     


      
      {

        path: '/Admin/rules',
        component: () => import('@/Admin/views/Rules.vue'),
      
      },
    

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
