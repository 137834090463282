<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <!-- <ion-img slot="end" style="max-width:200px;" src="text.png"> -->
         <ion-title slot="end"> Steady Forms </ion-title>
        <!-- </ion-img> -->
      </ion-toolbar>
    </ion-header>

<ion-content>

    
    <ion-item>
      <!-- <ion-text>Choose a Platform to proceed with</ion-text> -->
    </ion-item>
     <ion-item-divider>
     
  </ion-item-divider>

    <ion-item  @click="$root.presentLoading('Loading',2000); switchPlatform('agent','/agent/tabs/home')">
       <ion-img style="max-width:55px;" src="/logo.png" alt=""/>  
       <ion-text color="success">
          <p style=" font-size:18px" > 
            Survey Form<br>
           </p>
          <p class="caption">
            Collect data from schools
          </p>
       </ion-text>
    </ion-item>

    <ion-item  @click="$root.presentLoading('Loading',2000); switchPlatform('admin', '/admin/tabs/home')">    
      <ion-img style="max-width:55px; " src="/admin.png" alt=""/>
      <ion-text color="primary">
        <p style=" font-size:18px">
          Admin
        </p>
        <p class="caption">
         View & Manage Collected Data
        </p>
      </ion-text>
    </ion-item>
  
 
   
   

    <!-- <ion-item  @click="switchPlatform('meetup', $mu_url)">-->
     <!-- <ion-img style="max-width:55px;" src="/meetup/logo.png" alt=""/> -->
       
       <!-- <ion-text>
         <p style="color:#ff1c5e; font-size:18px">
             More from steady forms
         </p>
       
         <p class="caption">Request More from steady forms</p></ion-text>
    </ion-item> -->


   <ion-img></ion-img>


</ion-content>
 

  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonPage, IonToolbar, IonHeader, IonText, IonImg, IonItem, IonContent, alertController } from '@ionic/vue';
import { ellipse, square, triangle, home } from 'ionicons/icons';
import {Storage} from '@ionic/storage';
import { Capacitor } from '@capacitor/core';
const store =  (new Storage());

     
export default defineComponent({
  name: 'TabsPage',
  components: {IonPage, IonToolbar, IonHeader, IonText, IonImg, IonItem, IonContent },

   async created(){
      await store.create();

      if(!this.$pinia.allow_full_access)
        this.$router.replace('/index');
   
  },


  methods:{

    
 async store(){

  

  },

      async  switchPlatform(platform, url){

    
    this.$pinia.switchPlatform(url);
    await  store.set('current_platform', platform);
    this.$router.push({path: '/'+platform});

  
    },

      async convertToApp(){
      
     
       const alert = await alertController
        .create({
        
          cssClass: 'eula',
          subHeader: 'Convert To Mobile App',
          backdropDismiss: false,
          message: 'Due to the challenges we faced uploading our App to the Apple store, below are steps to convert this Web-App into an App on your home screen for the mean time<br> '+ 
                    '<br><b><bold>Steps (You can to do it now)</b></bold>'+ 
                    '<br>1. Ensure you are using Safari to do this.'+
                    '<br>2. Click the options menu at the bottom (The middle Icon) '+
                    '<br>3. Scroll and click Add to Home Screen'+
                    '<br>4. A menu will pop up with the App details, click add<br>'+
                    '<br><i>You can now visit the website directly from your homescreen just like an App</i>'+
                    '</small>',
          buttons: [
            {
              text: "Don't show me again",
              role: 'cancel',
              id: 'cancel-button',
              handler: () => {
                   
                   this.convertToAppDismiss('no');
              },
            },
            {
              text: 'Done',
              id: 'confirm-button',
              handler: () => {

                 this.convertToAppDismiss('yes');
              
              },
            },
          ],
        });
      alert.present();



    },

    async convertToAppDismiss(val){
     await store.set('cto', val);

    },



  },

  setup() {
    return {
      ellipse, 
      square, 
      triangle,
      home,
    }
  }
});
</script>
<style scoped>
ion-item>ion-img{
  margin:5%;
}


.caption{

font-size:12px; color:grey


}
</style>