import { defineStore } from 'pinia'
import axios from 'axios'
import {Storage} from '@ionic/storage';
const store = new Storage();
export const useStore = defineStore('storeId', {
  // arrow function recommended for full type inference
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      platform:'',
      id: 0,
      name:    [

       
      ],
      pic_url: '',
     // pic_url: '',
      user_id:'',
      meetup_id:'',
      matchmaker_id:'',
      allow_full_access:false,
      paystack_public_key: 'pk_live_e58241e21a8404efd479bbbc39dc68b8409cacad' ,
      matchmaker_mini:{'name': 'loading'},
      forum_data:{   
      edit_post:{},
      view_user:{},
       schools_done:'dddd'
    

      },

      agent:{

        'id':'',
        'name':'',
        'gender':'',
        'token':'',
        
      },
      user_data:{
        'id':'',
        'name':'',
        'pin':'',
        'token':'',
        'unseen_notifications':'',
        'kyc':{
          'id':null,
          'name':null,
          'status':null,
          'id_type':null,
          'id_picture':null,
          'id_type':null,
          'id_number':null,
        }
      
      },

      users:[
        {'code':'sfp1233', 'name':'agent 1'},
        {'code':'smo123', 'name':'agent 3'},
        {'code':'sfP0123', 'name':'agent 4'},
        {'code':'ssfp123', 'name':'agent 11'},
        {'code':'sfp03', 'name':'agent 10'},
        {'code':'sfdc23', 'name':'agent 2'},
        {'code':'mfor23', 'name':'agent 6'},
        {'code':'xpom23', 'name':'agent 5'},
        {'code':'90Bc3', 'name':'agent 9'},
        {'code':'foa23', 'name':'agent 12'},
        {'code':'maq23', 'name':'agent 7'},
        {'code':'amg63', 'name':'agent 13'},
        {'code':'amg43', 'name':'agent 14'},
        {'code':'f1p123', 'name':'agent 8'},  
        {'code':'amg43', 'name':'agent 14'},
        {'code':'335i', 'name':'agent 15'},  
        {'code':'m6compe', 'name':'agent 16'},  
        {'code':'650i', 'name':'agent 17'},  
        {'code':'440i', 'name':'agent 18'},  
        {'code':'f82m4', 'name':'agent 19'},  
        {'code':'e92m3', 'name':'agent 20'},  
        
      ],
      matchmaker_data:{
          'name':'',
          'id':'',
          'payment':'',
          'type':'',
          'unseen_notifications':'',
          'status':''
      },
      meetup_data:{
        'name':'',
        'id':'',
         'paid':'',
         'type':'',


      },
      my_account:{
        'gender':'',
        'email':''

      },
      user_token: null,
      current_platform: null,
      isAdmin: true,
    }
  },

  actions: {

    changeName(){

      this.name = 'helllo';

    },

    async switchPlatform(url){
    


  },

  getSchoolsDone(){
    return this.schools_done
  },

  async storeToken(data){
    await store.create();

      if(data == 'super_admin' || data == 'super admin'){
       
        this.allow_full_access = true;
        await store.set('token', 'super_admin');  
        await store.set('user_name', 'super admin'); 
        this.agent={
          'name':'super admin'
        }

        this.allow_full_access = true;
    }

   else if(data == 'admin' || data == 'Minda321'){
      await store.set('token', 'Minda321');  
      await store.set('user_name', 'admin'); 
      this.agent={
        'name':'admin'
      }
     
  }

  else{
 
   // await store.set('token', data.code);  
    await store.set('user_name', data.name); 
    await store.set('token', data.code); 
    this.agent = data;
    
  }
   
  
  },

 
  async setUserData( user){
//Minda321F1p123
    //  alert('meetup --'+JSON.stringify(meetup)+'--matchmaker--'+JSON.stringify(matchmaker)+'---forum-'+JSON.stringify(user));

   },




  },

  getters:{

    getCurrentPlatform(){
      return this.current_platform;
    },

    getAccountDetails(){
      return this.my_account;
    }
  }
})


//automatic next school loading
//search via school name and phone number
//load previously done schools
